import logo from './logo.svg';
import './App.css';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import '../src/assets/font/font-awesome.css'
import routes from './Routes.js';
import Page404 from './OtherPages/page404';
import FrontHeader from './Layouts/FrontHeader';
import { useEffect } from 'react';
import Footer from './Layouts/Footer';

function App() {



  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  return (
  <>
    <FrontHeader />
    <Routes>
      {
        routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))
      }
      <Route path="*" element={<Page404 />} />
    </Routes>

    <Footer />
  </>

  );
}

export default App;
