import AboutUs from "./AboutUs";
import FAQ from "./FAQ";
import HomePage from "./Home";
import Login from "./Login";
import Register from "./Register";
import ActiveAccount from "./ActiveAccount";
import Presale from "./Presale";
import UserDashboard from "./ProjectInfo";
import UnderConstructor from "./UnderConstructor";
import Contact from "./Contact";
import Tokenomics from "./Tokenomics/Index";

const routes = [
    {path : "/", component : <HomePage />},
    {path : "/about-us", component : <AboutUs />},
    {path : "/tokenomics", component : <Tokenomics />},
    {path : "/faq", component : <FAQ />},
    {path : "/login", component : <Login />},
    {path : "/register", component : <Register />},
    {path : "/presale", component : <Presale />},
    {path : "/comingsoon", component : <UnderConstructor />},
    {path : "/contact", component : <Contact />},
];

export default routes;