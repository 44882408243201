import ReactApexChart from 'react-apexcharts';
import img1 from '../assets/images/about_header_bg.png';
import './index.scss';
const Tokenomics = () => {
    var mySeries = [];
    var dti = [
        {
            name: 'Public Sale',
            cliff: 0, vesting: 1, amount: 5000000
        },
        {
            name: 'Private Sale',
            cliff: 3, vesting: 36, amount: 20000000
        },

        {
            name: 'Seed',
            cliff: 3, vesting: 36, amount: 5000000
        },
        {
            name: 'Team Members',
            cliff: 6, vesting: 54, amount: 75000000
        },
        {
            name: 'P2E Rewarding',
            cliff: 0, vesting: 60, amount: 165000000
        },


        {
            name: 'Staking',
            cliff: 0, vesting: 60, amount: 65000000
        },

        {
            name: 'Marketing',
            cliff: 0, vesting: 60, amount: 15000000
        },
        {
            name: 'Treasury & Ecosystem',
            cliff: 3, vesting: 57, amount: 45000000
        },
        {
            name: 'Liquidity',
            cliff: 0, vesting: 60, amount: 105000000
        },
    ];

    for (var i = 0; i < dti.length; i++) {
        var lastValue = 0;
        let item = dti[i];
        let obj = { name: item.name };
        var series = [];
        for (var c = 0; c < item.cliff; c++) {
            series.push(0);
        }
        var amount = item.amount / item.vesting;
        for (var v = 0; v < item.vesting; v++) {
            lastValue = amount;
            series.push(Math.round(amount));
            amount = Math.round(amount + (item.amount / item.vesting));
        }

        lastValue = amount;

        if (item.vesting < 60) {
            console.log(item);
            for (var f = item.vesting + item.cliff; f < 60; f++) {
                console.log(f, item);
                lastValue = amount;
                series.push(Math.round(lastValue));
            }
        }

        series.push(lastValue);
        obj['data'] = series;
        mySeries.push(obj);
    }

    var months = [];
    for (var i = 1; i <= 60; i++) {
        months.push(i);
    }

    const options = {
        chart: {
            height: 350,
            type: 'area',
            background: 'transparent',
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'number',
            categories: months
        },

        tooltip: {
            x: {
                format: 'dd/MM/yyyy '
            },
            y: {
                formatter: undefined,
                title: {
                    formatter: (seriesName) => seriesName
                },
            },
            z: {
                formatter: undefined,
                title: 'Size: '
            },
        },
        theme: {
            mode: 'dark',
            palette: 'palette2',
            monochrome: {
                enabled: false,
                color: '#255aee',
                shadeTo: 'dark',
                shadeIntensity: 0.65

            },
        }
    }

    const pieseries = [
        5000000,
        20000000,
        5000000,
        75000000,
        165000000,
        65000000,
        15000000,
        45000000,
        105000000,
    ]
    const pieoptions = {
        chart: {
            width: 380,
            type: 'pie',
            background :'transparent'
            
        },
        theme: {
            mode: 'dark',
            palette: 'palette2',
            monochrome: {
                enabled: false,
                color: '#255aee',
                shadeTo: 'dark',
                shadeIntensity: 0.65

            },
        },
        labels: [
            'Public Sale',
            'Private Sale',
            'Seed',
            'Team Members',
            'P2E Rewarding',
            'Staking',
            'Marketing',
            'Treasury & Ecosystem',
            'Liquidity',
        ],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    return (
        <>
            <section className="breadcrumb-area" style={{
                backgroundImage: `url(${img1})`,

            }}>
                <div className="container">
                    <div className="breadcrumb__wrapper">

                    </div>
                </div>
            </section>

            <div className="container">
                <section className="services-area services__bg-color section-pt-120 section-pb-120 mt-5" style={{ padding: '0' }}>
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <h4>60-Month Cliff and Vesting Plan</h4>
                            <ReactApexChart options={options} series={mySeries} type="area" height={350} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <h4>Amounts to be Distributed</h4>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <ReactApexChart options={pieoptions} series={pieseries} type="pie" height={350} />
                                </div>
                                <div className='col-md-6'>
                                    <table className='table'>
                                        <tbody>
                                            <tr><th>Public Sale</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(5000000)}</td>
                                            <td>1%</td>
                                            </tr>
                                            <tr><th>Private Sale</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(20000000)}</td>
                                            <td>4%</td>
                                            </tr>
                                            <tr><th>Seed</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(5000000)}</td>
                                            <td>1%</td>
                                            </tr>
                                            <tr><th>Team Members</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(75000000)}</td>
                                            <td>15%</td>
                                            </tr>
                                            <tr><th>P2E Rewarding</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(165000000)}</td>
                                            <td>33%</td>
                                            </tr>
                                            <tr><th>Staking</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(65000000)}</td>
                                            <td>13%</td>
                                            </tr>
                                            <tr><th>Marketing</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(15000000)}</td>
                                            <td>3%</td>
                                            </tr>
                                            <tr><th>Treasury & Ecosystem</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(45000000)}</td>
                                            <td>9%</td>
                                            </tr>
                                            <tr><th>Liquidity</th>
                                            <td>{new Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(105000000)}</td>
                                            <td>21%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
export default Tokenomics;