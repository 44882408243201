
const menus = [
    {
        id : 1,
        name : "Home",
        links : "/"
    },
    {
        id : 2,
        name : "About Us",
        links : "/about-us"
    },
    {
        id : 66,
        name : "Tokenomics",
        links : "/tokenomics"
    },
   
    
    {
        id : 4,
        name : "FAQ",
        links : "/faq"
    },
    {
        id : 5,
        name : "Presale",
        links : "/presale"
    },
    {
        id : 5,
        name : "Contact Us",
        links : "/contact"
    },
];
export default menus;