import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageLoading: null,
  minBuy: 20,
  provider: null,
  signer: null,
  account: null,
  tokenName: 'USDT',
  isSwitch: false,
  totalInvestment : 0,
  isApproved: false,
  networkName: "USDT (BEP20)",
  network: "usdt-bep20",
  networkCode: "BEP20",
  chainId: null,
  fromAmount: 0,
  toAmount: 0,
  loadingBuy: false,
  loadingBalance: false,
  loadingPage: false,
  tokenPrice: 0,
  tokenBalance : 0,
  mafBalance: 0,
  allowanceVal: 0,
  bnbPrice : 0
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setPageLoading : (state, action) => {
      state.pageLoading = action.payload;
    },
    setMinBuy : (state, action) => {
      state.minBuy = action.payload;
    },
    setTotalInvestment : (state, action) => {
      state.totalInvestment = action.payload;
    },
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
    setSigner: (state, action) => {
      state.signer = action.payload;
    },
    
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setTokenName: (state, action) => {
      state.tokenName = action.payload;
    },
    setIsSwitch: (state, action) => {
      state.isSwitch = action.payload;
    },
    setIsApproved: (state, action) => {
      state.isApproved = action.payload;
    },
    setNetworkName: (state, action) => {
      state.networkName = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setNetworkCode: (state, action) => {
      state.networkCode = action.payload;
    },
    setChainId: (state, action) => {
      state.chainId = action.payload;
    },
    setFromAmount: (state, action) => {
      state.fromAmount = action.payload;
    },
    setToAmount: (state, action) => {
      state.toAmount = action.payload;
    },
    setLoadingBuy: (state, action) => {
      state.loadingBuy = action.payload;
    },
    setLoadingBalance: (state, action) => {
      state.loadingBalance = action.payload;
    },
    setLoadingPage: (state, action) => {
      state.loadingPage = action.payload;
    },
    setTokenPrice: (state, action) => {
      state.tokenPrice = action.payload;
    },
    setTokenBalance: (state, action) => {
      state.tokenBalance = action.payload;
    },
    setMafBalance: (state, action) => {
      state.mafBalance = action.payload;
    },
    setAllowanceVal: (state, action) => {
      state.allowanceVal = action.payload;
    },
    setBNBPrice : (state, action) => {
      state.bnbPrice = action.payload;
    }
  },
});

export const { setPageLoading, setMinBuy, setTotalInvestment, setProvider, setSigner, setAccount, setAllowanceVal, setToAmount, setChainId, setFromAmount, setIsApproved, setIsSwitch, setLoadingBalance, setLoadingBuy, setLoadingPage, setMafBalance, setNetwork, setNetworkCode, setNetworkName, setTokenName, setTokenPrice, setTokenBalance, setBNBPrice } =
  dataSlice.actions;

export default dataSlice.reducer;